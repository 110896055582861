import { createSlice } from '@reduxjs/toolkit';

const initialState = { branches: [], branch: {} };

const slice = createSlice({
  name: 'AdminBranchReducer',
  initialState,
  reducers: {
    SAVE_ALL_BRANCH: (state, action) => {
      state.branches = action.payload;
    },
    GET_SINGLE_ADMIN: (state, action) => {
      state.branch = action.payload;
    },
  },
});

export const { SAVE_ALL_BRANCH, GET_SINGLE_ADMIN } = slice.actions;

export default slice.reducer;
