import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: {},
  product: {},
  productsInWarehouse: {},
  adminStore: {},
};

const slice = createSlice({
  name: 'AdminProductReducer',
  initialState,
  reducers: {
    GET_PRODUCTS_ADMIN: (state, action) => {
      state.products = action.payload;
    },
    GET_PRODUCT_ADMIN: (state, action) => {
      state.product = action.payload;
    },
    GET_PRODUCTS_IN_WAREHOUSE_ADMIN: (state, action) => {
      state.productsInWarehouse = action.payload;
    },
    GET_STORES_ADMIN: (state, action) => {
      state.adminStore = action.payload;
    },
  },
});

export const { GET_PRODUCTS_IN_WAREHOUSE_ADMIN, GET_PRODUCT_ADMIN, GET_PRODUCTS_ADMIN, GET_STORES_ADMIN } =
  slice.actions;

export default slice.reducer;
