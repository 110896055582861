import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invoices: {},
  stations: {},
  transactions: {},
  itemPrices: {},
  multiPaymentMethods: {},
};

const slice = createSlice({
  name: 'AdminReportReducer',
  initialState,
  reducers: {
    GET_INVOICE_ADMIN: (state, action) => {
      state.invoices = action.payload;
    },
    GET_STATION_REPORT: (state, action) => {
      state.stations = action.payload;
    },
    GET_TRANSACTION_REPORT: (state, action) => {
      state.transactions = action.payload;
    },
    GET_ITEM_PRICE_REPORT: (state, action) => {
      state.itemPrices = action.payload;
    },
    GET_MULTI_PAY_REPORT: (state, action) => {
      state.multiPaymentMethods = action.payload;
    },
  },
});

export const {
  GET_INVOICE_ADMIN,
  GET_STATION_REPORT,
  GET_TRANSACTION_REPORT,
  GET_ITEM_PRICE_REPORT,
  GET_MULTI_PAY_REPORT,
} = slice.actions;

export default slice.reducer;
