import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invoices: {},
  // invoices: [],
};

const slice = createSlice({
  name: 'AdminInvoiceReducer',
  initialState,
  reducers: {
    SAVE_INVOICES: (state, action) => {
      state.invoices = action.payload;
    },
  },
});

export const { SAVE_INVOICES } = slice.actions;

export default slice.reducer;
