import { LocalStorage } from '../../Services';

const CONFIG = {
  PERSISTENT_LANGUAGE_KEY: 'language',
};

export const isRtl = (languageCode) => {
  switch (languageCode) {
    // Define RTL languages here
    case 'ar':
      return true;
    default:
      return false;
  }
};

export const persistLanguage = (languageCode) => {
  LocalStorage.set(CONFIG.PERSISTENT_LANGUAGE_KEY, languageCode);
};

export const getPersistentLanguage = (defaultLanguageCode) => {
  return LocalStorage.get(CONFIG.PERSISTENT_LANGUAGE_KEY) ?? defaultLanguageCode;
};
