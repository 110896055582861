import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tickets: {}
};

const slice = createSlice({
  name: 'ticketReducer',
  initialState,
  reducers: {
    SAVE_ALL_TICKETS: (state, action) => {
      state.tickets = action.payload;
    },
  },
});

export const { SAVE_ALL_TICKETS } = slice.actions;

export default slice.reducer;
