import { createSlice } from '@reduxjs/toolkit';
import { getPersistentLanguage } from '../../utils/helpers';

const initialState = {
  isSidebarVisible: true,
  isLoadingPopupVisible: false,
  language: getPersistentLanguage('ar'),
};

const slice = createSlice({
  name: 'ThemeReducer',
  initialState,
  reducers: {
    SET_SIDEBAR_VISIBILITY: (state, action) => {
      state.isSidebarVisible = action.payload;
    },
    SET_LOADING_POPUP_VISIBILITY: (state, action) => {
      state.loading = action.payload;
    },
    SET_LANGUAGE: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { SET_SIDEBAR_VISIBILITY, SET_LOADING_POPUP_VISIBILITY, SET_LANGUAGE } = slice.actions;

export default slice.reducer;
