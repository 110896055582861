const { createSlice } = require('@reduxjs/toolkit');

const slice = createSlice({
  name: 'currencyReducer',
  initialState: { currencies: {} },
  reducers: {
    GET_CURRENCY: (state, action) => {
      state.currencies = action.payload;
    },
  },
});

export const { GET_CURRENCY } = slice.actions;

export default slice.reducer;
