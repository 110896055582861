import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import SuperAdminReducer from './super/admin/admin.slice';
import SuperEmployeeReducer from './super/employee/employee.slice';
import SuperUnitReducer from './super/unit/unit.slice';
import SuperDiscountReducer from './super/discount/discount.slice';
import SuperPaymentMethodReducer from './super/paymentMethod/paymentMethod.slice';
import SuperProductReducer from './super/product/product.slice';
import SuperShopCustomerReducer from './super/shopCustomer/shopCustomer.slice';
import SuperReportReducer from './super/report/report.slice';
import SuperRoleReducer from './super/role/role.slice';
import SuperStoreReducer from './super/store/store.slice';
import SuperStationReducer from './super/station/station.slice';
import SuperTaxReducer from './super/tax/tax.slice';
import SuperUserReducer from './super/user/user.slice';
import SuperWarehouseReducer from './super/warehouse/warehouse.slice';
import SuperBranchReducer from './super/branch/branch.slice';
import GeneralReducer from './general/general.slice';
import EmployeeInvoiceReducer from './employee/invoice/invoice.slice';
import AdminCustomerReducer from './admin/customer/customer.slice';
import AdminEmployeeReducer from './admin/employee/employee.slice';
import AdminInvoiceReducer from './admin/invoice/invoice.slice';
import AdminRefundsReducer from './admin/reFunds/refunds.slice';
import AdminProductReducer from './admin/product/product.slice';
import AdminUserReducer from './admin/user/user.slice';
import AdminReportReducer from './admin/report/report.slice';
import AdminStationReducer from './admin/station/station.slice';
import AdminWarehouseReducer from './admin/warehouse/warehouse.slice';
import AdminBranchReducer from './admin/branch/branch.slice';
import AdminCategoryReducer from './admin/category/category.slice';
import EmployeeCategoryReducer from './employee/category/category.slice';
import AdminPrintSchemaReducer from './admin/printSchema/printSchema.slice';
import AdminTransactionReducer from './admin/transaction/transaction.slice';
import ThemeReducer from './theme/theme.slice';
import EmployeeProductReducer from './employee/product/product.slice';
import EmployeeStationReducer from './employee/station/station.slice';
import AdminPaymentMethodReducer from './admin/paymentMethod/paymentMethod.slice';
import AdminUnitReducer from './admin/unit/unit.slice';
import AdminDiscountReducer from './admin/discount/discount.slice';
import AdminRoleReducer from './admin/role/role.slice';
import AdminPermissionReducer from './admin/permission/permission.slice';
import SuperPermissionReducer from './super/permission/permission.slice';
import AdminAuthReducer from './admin/auth/auth.slice';
import AdminVoucherReducer from './admin/voucher/voucher.slice';
import SuperVoucherReducer from './super/voucher/voucher.slice';
import SuperAuthReducer from './super/auth/auth.slice';
import EmployeeAuthReducer from './employee/auth/auth.slice';
import EmployeePaymentMethodReducer from './employee/paymentMethod/paymentMethod.slice';
import AdminTaxReducer from './admin/tax/tax.slice';
import SuperTransactionReducer from './super/transaction/transaction.slice';
import ProductPackageReducer from './super/productPackage/productPackage.slice';
import productPackageReducerEmployee from './employee/productPackage/productPackage.slice';
import productPackageReducerAdmin from './admin/productPackage/productPackage.slice';
import sassReducer from './Sass/Sass/sass.slice';
import superAdminReducer from './Sass/SuperAdmin/superAdmin.slice';
import sassRoleReducer from './Sass/role/role.slice';
import sassPermissionReducer from './Sass/permission/permission.slice';
import superCategoryReducer from './super/category/category.slice';
import currencyReducer from './general/currency/currency.slice';
import ticketReducer from './Sass/TicketManagment/ticket.slice';
import ticketReducerSuperAdmin from './super/TicketManagment/ticket.slice';
import saasManagerReducer from './Sass/saasManager/saasManager.slice';

export default configureStore({
  reducer: {
    // #region General reducers
    general: GeneralReducer,
    theme: ThemeReducer,
    currency: currencyReducer,
    // #endregion

    // #region Super reducers
    superAdmin: SuperAdminReducer,
    superEmployee: SuperEmployeeReducer,
    superUnit: SuperUnitReducer,
    superDiscount: SuperDiscountReducer,
    superPaymentMethod: SuperPaymentMethodReducer,
    superProduct: SuperProductReducer,
    superShopCustomer: SuperShopCustomerReducer,
    superReport: SuperReportReducer,
    superRole: SuperRoleReducer,
    superBranch: SuperBranchReducer,
    superAuth: SuperAuthReducer,
    superStore: SuperStoreReducer,
    superPermission: SuperPermissionReducer,
    superStation: SuperStationReducer,
    superTax: SuperTaxReducer,
    superUser: SuperUserReducer,
    superWarehouse: SuperWarehouseReducer,
    superVoucher: SuperVoucherReducer,
    superCategory: superCategoryReducer,
    // #endregion

    // #region Admin reducers
    adminCustomer: AdminCustomerReducer,
    adminEmployee: AdminEmployeeReducer,
    adminBranch: AdminBranchReducer,
    adminCategory: AdminCategoryReducer,
    adminInvoice: AdminInvoiceReducer,
    adminReFunds: AdminRefundsReducer,
    adminProduct: AdminProductReducer,
    adminUser: AdminUserReducer,
    adminReport: AdminReportReducer,
    adminStation: AdminStationReducer,
    adminTransaction: AdminTransactionReducer,
    adminPaymentMethod: AdminPaymentMethodReducer,
    adminUnit: AdminUnitReducer,
    adminDiscount: AdminDiscountReducer,
    adminRole: AdminRoleReducer,
    adminPermission: AdminPermissionReducer,
    adminAuth: AdminAuthReducer,
    adminTax: AdminTaxReducer,
    adminPrintSchema: AdminPrintSchemaReducer,
    adminWarehouse: AdminWarehouseReducer,
    adminVoucher: AdminVoucherReducer,
    // #endregion

    // #region Employee reducers
    employeeInvoice: EmployeeInvoiceReducer,
    employeeProduct: EmployeeProductReducer,
    employeeStation: EmployeeStationReducer,
    employeeCategory: EmployeeCategoryReducer,
    employeeAuth: EmployeeAuthReducer,
    employeePaymentMethod: EmployeePaymentMethodReducer,
    superTransactionReducer: SuperTransactionReducer,
    productPackageReducer: ProductPackageReducer,
    ProductPackageReducerEmployee: productPackageReducerEmployee,
    ProductPackageReducerAdmin: productPackageReducerAdmin,
    sassRole: sassRoleReducer,
    sassPermission: sassPermissionReducer,
    SassReducer: sassReducer,
    saasManagerReducer,
    SuperAdminReducer: superAdminReducer,
    TicketReducer: ticketReducer,
    TicketReducerSuperAdmin: ticketReducerSuperAdmin,
    // #endregion
  },
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware().concat(thunk);

    if (process.env.NODE_ENV === 'development') {
      middleware = middleware.concat(logger);
    }

    return middleware;
  },
  devTools: process.env.NODE_ENV === 'development',
});
