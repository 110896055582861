/* eslint-disable import/no-cycle */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
import axios from 'axios';
import { isUndefined } from 'underscore';
import { returnBaseUrl } from '../../utils/helpers/globalFunctions';

// import { returnBaseUrl } from '../../utils/helpers/globalFunctions';

// if (process.env.NODE_ENV === 'development') axios.defaults.baseURL = process.env.REACT_APP_LOCAL_HOST;
// // if (process.env.NODE_ENV === 'development') axios.defaults.baseURL = process.env.REACT_APP_LOCAL_HOST;
// else {
//   // axios.defaults.baseURL = 'http://localhost:3002/backend/';
//   // axios.defaults.baseURL = 'http://54.194.234.210:3000/backend/';
//   axios.defaults.baseURL = 'https://osuspos.com/backend/';
//   // axios.defaults.baseURL = returnBaseUrl(true);
// }

// axios.defaults.baseURL = 'http://3.250.156.38/backend/'
axios.defaults.baseURL = returnBaseUrl(true);
// axios.defaults.baseURL = returnBaseUrl(true);

const Http = {
  /**
   * Send a request with POST method.
   *
   * @param {string} url
   * @param {object} data Optional
   * @param {string} token Optional
   * @returns
   */
  async post(url, data, token, options) {
    return axios.post(url, !isUndefined(data) ? JSON.stringify(data) : data, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        'x-auth': token,
      },
    });
  },

  /**
   * Send a request with PATCH method.
   *
   * @param {string} url
   * @param {object} data Optional
   * @param {string} token Optional
   * @returns
   */
  async patch(url, data, token) {
    return axios.patch(url, !isUndefined(data) ? JSON.stringify(data) : data, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth': token,
      },
    });
  },

  /**
   * Send a request with POST method and multipart content type.
   *
   * @param {string} url
   * @param {object} data Optional
   * @param {string} token Optional
   * @returns
   */
  async postMultiPart(url, data, token) {
    return axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth': token,
      },
    });
  },

  /**
   * Send a request with POST method and multipart content type.
   *
   * @param {string} url
   * @param {object} data Optional
   * @param {string} token Optional
   * @returns
   */
  async patchMultiPart(url, data, token) {
    return axios.patch(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth': token,
      },
    });
  },

  /**
   * Send a request with GET method
   *
   * @param {string} url
   * @param {object} data Optional
   * @param {string} token Optional
   * @returns
   */
  async get(url, data, token, isBlob) {
    if (isBlob) {
      return axios.get(url, {
        data: !isUndefined(data) ? JSON.stringify(data) : data,
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
        responseType: 'blob',
      });
    } else {
      return axios.get(url, {
        data: !isUndefined(data) ? JSON.stringify(data) : data,
        headers: {
          'Content-Type': 'application/json',
          'x-auth': token,
        },
      });
    }
  },

  /**
   * Send a request with DELETE method.
   *
   * @param {string} url
   * @param {object} data Optional
   * @param {string} token Optional
   * @returns
   */
  async delete(url, data, token) {
    return axios.delete(url, {
      data: !isUndefined(data) ? JSON.stringify(data) : data,
      headers: {
        'Content-Type': 'application/json',
        'x-auth': token,
      },
    });
  },

  /**
   * Send a request with PUT method.
   *
   * @param {string} url
   * @param {object} data Optional
   * @param {string} token Optional
   * @returns
   */
  async put(url, data, token) {
    return axios.put(url, !isUndefined(data) ? JSON.stringify(data) : data, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth': token,
      },
    });
  },
};

export default Http;
