import { createSlice } from '@reduxjs/toolkit';

const initialState = { branches: {}, branch: {} };

const slice = createSlice({
  name: 'SuperBranchReducer',
  initialState,
  reducers: {
    GET_ALL_SUPER: (state, action) => {
      state.branches = action.payload;
    },
    GET_SINGLE_SUPER: (state, action) => {
      state.branch = action.payload;
    },
  },
});

export const { GET_ALL_SUPER, GET_SINGLE_SUPER } = slice.actions;

export default slice.reducer;
