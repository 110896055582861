import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sassPermissions: {},
};

const slice = createSlice({
  name: 'SassPermissionReducer',
  initialState,
  reducers: {
    GET_SASS_PERMISSIONS: (state, action) => {
      state.sassPermissions = action.payload;
    },
  },
});

export const { GET_SASS_PERMISSIONS } = slice.actions;

export default slice.reducer;
