import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sassRoles: {},
};

const slice = createSlice({
  name: 'SassRoleReducer',
  initialState,
  reducers: {
    GET_SASS_ROLES: (state, action) => {
      state.sassRoles = action.payload;
    },
  },
});

export const { GET_SASS_ROLES } = slice.actions;

export default slice.reducer;
