/* eslint-disable react/jsx-curly-brace-presence */
import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import englishTranslations from './i18n/en.json';
import arabicTranslations from './i18n/ar.json';
import { ROUTE_PREFIX } from './utils/constants';
import { ProtectedRoute, GuardedRoute } from './components/routes';
import { isRtl } from './utils/helpers';
// LoadingPopUp should be imported directly, otherwise returning to the previous url after login doesn't work properly
import LoadingPopUp from './components/ui/LoadingPopup/LoadingPopup';
// import './scss/style.scss';
import './assets/css/index.scss';
import './styles/globals.css';

import { LocalStorage } from './Services';

// Containers
const AdminLayout = lazy(() => import('./components/layouts/AdminLayout/AdminLayout'));
const SuperLayout = lazy(() => import('./components/layouts/SuperLayout/SuperLayout'));
const EmployeeLayout = lazy(() => import('./components/layouts/EmployeeLayout/EmployeeLayout'));
const SassLayout = lazy(() => import('./components/layouts/SassLayout/SassLayout'));

// Pages
const SuperLogin = lazy(() => import('./views/super/Login/Login'));
const SassLogin = lazy(() => import('./views/SassLayout/Login/Login'));
const AdminLogin = lazy(() => import('./views/admin/Login/Login'));
const EmployeeLogin = lazy(() => import('./views/employee/Login/LoginPage/LoginPage'));
const ShopPrivate = lazy(() => import('./views/Private/PrivatePage/ShopPrivate'));
const ErrorNotFound = lazy(() => import('./views/Errors/NotFoundPage/NotFoundPage'));
const ErrorInternal = lazy(() => import('./views/Errors/InternalErrorPage/InternalErrorPage'));
const ErrorNotAuthorized = lazy(() => import('./views/Errors/NotAuthorizedPage/NotAuthorizedPage'));
const PrivateLogin = lazy(() => import('./views/Private/PrivatePage/PrivatePage'));
const SaasPrivate = lazy(() => import('./views/SassLayout/PrivatePage/PrivatePage'));
const LandingPage = lazy(() => import('./views/Private/LandingPage/LandingPage'));

const messages = {
  en: englishTranslations,
  ar: arabicTranslations,
};

function App() {
  const currentLanguage = useSelector((state) => state.theme.language);
  const [isCurrentLanguageRtl, setIsCurrentLanguageRtl] = useState(false);

  // const sassName = window.location.href.split('/')?.[3];
  // const dispatch = useDispatch();

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //  document.write('mobile');
      LocalStorage.set('isMobile', true);
    } else {
      LocalStorage.set('isMobile', false);
    }
    setIsCurrentLanguageRtl(isRtl(currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      // Supported!
      navigator.serviceWorker.register('/sw.js');
      // .then((registered) => console.log('se registered', registered))
      // .catch((err) => console.log('register sw occured an error', err));
      // console.log('serviceWorker Supported');
    } else {
      // console.log('serviceWorker has not Supported');
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((a) => {
        // console.log('Response,,,..??///', a);
        if (a.active.state === 'activated') {
          // setSwRsulte(true);
          // console.log('sw nooooootttttt');
        }
      });
    } else {
      // console.log('serviceWorker has not Supported in activation step');
    }
  }, []);

  useEffect(() => {
    if (currentLanguage === 'ar') {
      document.body.style.fontFamily = 'Tajawal';
    } else {
      document.body.style.fontFamily = 'Poppins';
    }
  }, [currentLanguage]);

  // const sassName = window.location.href.split('/')?.[3];
  const saas = JSON.parse(localStorage.getItem('sassInfo'));
  const sassName = saas ? saas.urlPath : null;
  return (
    <>
      <Helmet
        titleTemplate="%s | OsusPos"
        htmlAttributes={{ dir: isCurrentLanguageRtl ? 'rtl' : 'ltr', lang: currentLanguage }}
      >
        <link
          href={isCurrentLanguageRtl ? '/assets/css/coreui.rtl.min.css' : '/assets/css/coreui.min.css'}
          rel="stylesheet"
        />
      </Helmet>
      <IntlProvider messages={messages[currentLanguage]} locale={currentLanguage} defaultLocale="en">
        <BrowserRouter>
          <Suspense fallback={<div />}>
            <Switch>
              <GuardedRoute
                panel="super"
                exact
                path={`${ROUTE_PREFIX.SUPER}/login`}
                redirectTo={ROUTE_PREFIX.SUPER}
                component={SuperLogin}
              />
              <GuardedRoute
                panel="employee"
                exact
                path={`${ROUTE_PREFIX.EMPLOYEE}/login`}
                redirectTo={ROUTE_PREFIX.EMPLOYEE}
                component={EmployeeLogin}
              />
              <GuardedRoute
                panel="admin"
                component={AdminLogin}
                exact
                redirectTo={ROUTE_PREFIX.ADMIN}
                path={`${ROUTE_PREFIX.ADMIN}/login`}
              />
              <GuardedRoute
                panel="sass"
                component={SassLogin}
                exact
                redirectTo={ROUTE_PREFIX.SASS}
                path={`${ROUTE_PREFIX.SASS}/login`}
              />

              <ProtectedRoute
                panel="super"
                component={SuperLayout}
                redirectTo={ROUTE_PREFIX.SUPER}
                path={ROUTE_PREFIX.SUPER}
              />
              <ProtectedRoute
                panel="admin"
                component={AdminLayout}
                redirectTo={ROUTE_PREFIX.ADMIN}
                path={ROUTE_PREFIX.ADMIN}
              />
              <ProtectedRoute
                panel="employee"
                component={EmployeeLayout}
                redirectTo={ROUTE_PREFIX.EMPLOYEE}
                path={ROUTE_PREFIX.EMPLOYEE}
              />
              <ProtectedRoute
                panel="sass"
                component={SassLayout}
                redirectTo={ROUTE_PREFIX.SASS}
                path={ROUTE_PREFIX.SASS}
              />
              <Route exact path="/shopPrivate" component={ShopPrivate} />
              <Route exact path={ROUTE_PREFIX.PRIVATE} component={PrivateLogin} />
              <Route exact path="/private" component={SaasPrivate} />
              {/* <Route exact path="/" component={LandingPage} /> */}
              <Route exact path="/LandingPage" component={LandingPage} />
              <Route exact path="/403" component={ErrorNotAuthorized} />
              <Route exact path="/404" component={ErrorNotFound} />
              <Route exact path="/500" component={ErrorInternal} />

              <Route exact path={`/${sassName}/dashboard`} component={PrivateLogin} />
              <Redirect from={`/${null}`} to="/LandingPage" />
              <Redirect from={`/${sassName}`} to={`/${sassName}/dashboard`} />
              {/* temp area */}
              {/* <Route exact path={'/test'} component={SaasPrivate} /> */}
              {/* <Redirect from={`/`} to={'/mahdi1234/dashboard'} /> */}

              {/* temp area */}
              <Redirect from="/" to={`${sassName ? `${sassName}/dashboard` : 'LandingPage'}`} exact />
              {/* <Redirect from={`/`} to={`/${sassName}/dashboard`} exact /> */}

              {/* <Redirect from="/pwa" to={`/${sassName}/dashboard`} /> */}
            </Switch>
          </Suspense>

          <LoadingPopUp />
          <ToastContainer position="bottom-left" limit={3} autoClose={3000} draggableDirection="y" />
        </BrowserRouter>
      </IntlProvider>
    </>
  );
}

export default App;
