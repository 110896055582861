const SASS_ENDPOINT = {
  AUTH: {
    LOGIN: 'l0/auth/manager/login',
  },
  DASHBOARD: {
    STATISTICS: 'l0/saas/allSaasStatistics',
    ORDER_SUMMERY: 'l0/saas/salesSummery',
    MOST_SOLD_PRODUCTS: 'l0/saas/mostSoldSaas',
  },
  SASS: {
    GET_SASS: 'l0/saas/get-saas',
    CREATE_SASS: 'l0/saas/create-saas',
    UPDATE_SASS: 'l0/saas/modify-saas',
    DELETE: 'l0/saas/delete-saas',
    DELETE_ALL: 'l0/saas/all',
    ARCHIVE: 'l0/saas/archive',
    ROLLBACK: 'l0/saas/roll_back',
    GET_TICKETS: 'l0/ticket/get-ticket',
    UPDATE_TICKETS: 'l0/ticket/update-ticket',
    SEND_TICKETS: 'l0/ticket/send-msg',
    STATISTICS: 'l0/saas/statistics',
  },
  SUPERADMIN: {
    GET_SUPER_ADMIN: 'l0/get-superAdmin',
    CREATE_SUPER_ADMIN: 'l0/create-superAdmin',
    UPDATE_SUPER_ADMIN: 'l0/modify-superAdmin',
  },
  SAAS_MANAGER: {
    CREATE: 'l0/saasManager',
    UPDATE: 'l0/saasManager',
    GET: 'l0/saasManager',
  },
  ROLE: {
    CREATE: 'l0/roles/create-role1',
    UPDATE: 'l0/roles/edit-role1',
    GET_ALL: 'l0/roles/get-roles',
  },
  IMPERSONATE: {
    IMPERSONATE: 'l0/impersonate/super-admin',
  },
  PERMISSION: {
    GET: 'l0/roles/get-perm1',
  },
  BACKUP: {
    CREATE: 'l0/backup/create',
    RESTORE: 'l0/backup/restore',
  },
};

export default SASS_ENDPOINT;
