import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  printSchema: {},
  printSchemas: [],
};

const slice = createSlice({
  name: 'AdminPrintSchemaReducer',
  initialState,
  reducers: {
    GET_ONE_PRINT_SCHEMA_ADMIN: (state, action) => {
      const newSchema = action.payload;
      newSchema.design = newSchema.design? JSON.parse(newSchema.design) : {}
      state.printSchema = newSchema;
    },
    GET_ALL_PRINT_SCHEMAS_ADMIN: (state, action) => {
      state.printSchemas = action.payload;
    }
  },
});

export const { GET_ONE_PRINT_SCHEMA_ADMIN, GET_ALL_PRINT_SCHEMAS_ADMIN } = slice.actions;

export default slice.reducer;
