import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adminRoles: {},
  employeeRoles: {},
};

const slice = createSlice({
  name: 'AdminRoleReducer',
  initialState,
  reducers: {
    GET_ADMIN_ROLES_ADMIN: (state, action) => {
      state.adminRoles = action.payload;
    },
    GET_EMPLOYEE_ROLES_ADMIN: (state, action) => {
      state.employeeRoles = action.payload;
    },
  },
});

export const { GET_ADMIN_ROLES_ADMIN, GET_EMPLOYEE_ROLES_ADMIN } = slice.actions;

export default slice.reducer;
