import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: {},
  product: {},
  productsInWarehouse: {},
};

const slice = createSlice({
  name: 'SuperProductReducer',
  initialState,
  reducers: {
    GET_PRODUCTS_SUPER: (state, action) => {
      state.products = action.payload;
    },
    GET_PRODUCT_SUPER: (state, action) => {
      state.product = action.payload;
    },
    GET_PRODUCTS_IN_WAREHOUSE_SUPER: (state, action) => {
      state.productsInWarehouse = action.payload;
    },
  },
});

export const { GET_PRODUCTS_IN_WAREHOUSE_SUPER, GET_PRODUCTS_SUPER, GET_PRODUCT_SUPER } = slice.actions;

export default slice.reducer;
