import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  superPermissions: {},
  adminPermissions: {},
  employeePermissions: {},
};

const slice = createSlice({
  name: 'SuperPermissionReducer',
  initialState,
  reducers: {
    GET_SUPER_PERMISSIONS_SUPER: (state, action) => {
      state.superPermissions = action.payload;
    },
    GET_ADMIN_PERMISSIONS_SUPER: (state, action) => {
      state.adminPermissions = action.payload;
    },
    GET_EMPLOYEE_PERMISSIONS_SUPER: (state, action) => {
      state.employeePermissions = action.payload;
    },
  },
});

export const { GET_SUPER_PERMISSIONS_SUPER, GET_ADMIN_PERMISSIONS_SUPER, GET_EMPLOYEE_PERMISSIONS_SUPER } =
  slice.actions;

export default slice.reducer;
