const ADMIN_ENDPOINT = {
  AUTH: {
    LOGIN: 'l2/auth/shop-admin/login',
  },

  INVOICE: {
    CREATE: 'l2/invoices/create-invoice/invoice',
    GET: 'l2/invoices/get-invoices',
    OVERRIDEPRICE: 'l2/invoices/override-price',
    RETURNITEM: 'l2/invoices/return-item',
  },

  DASHBOARD: {
    STATISTICS: 'l2/dashboard',
    ORDER_SUMMERY: 'l2/dashboard/orderSummery',
    All_INVOICES: 'l2/dashboard/allInvoices',
    EMPLOYEES: 'l2/dashboard/employees',
    TOP_CUSTOMERS: 'l2/dashboard/topCustomers',
    MOST_SOLD_PRODUCTS: 'l2/dashboard/mostSoldProducts',
  },

  SCHEMA: {
    SAVE: 'l2/reports/report/add-design',
    GETONE: 'l2/reports/report/get-design',
    GETAll: 'l2/reports/report/get-all-design',
  },

  DISCOUNT_INVOICE: {
    CREATE: 'l2/discounts/add_discount_invoice',
    GET_ALL: 'l2/discounts/get_discount_invoice',
    GET_BY_ID: 'l2/discounts/get_single_discount_invoice',
    UPDATE: 'l2/discounts/update_discount_invoice',
  },

  INVOICE_POINT: {
    CREATE: 'l2/discounts/add_invoice_point',
    GET_ALL: 'l2/discounts/get_invoice_point',
    UPDATE: 'l2/discounts/update_invoice_point',
    GET_BY_ID: 'l2/discounts/get_single_invoice_point',
  },

  EMPLOYEE: {
    CREATE: 'l2/admin/employees/add',
    GET_ALL: 'l2/admin/employees/get',
    DISABLE: 'l2/admin/employees/disableEmployee',
    ENABLE: 'l2/admin/employees/enableEmployee',
    UPDATE: 'l2/admin/employees/update-employee',
  },

  USER: {
    GET_ALL: 'l2/admin/shop-admins',
    CREATE: 'l2/admin/new-admin',
    UPDATE: 'l2/admin/update-admin',
  },

  REPORT: {
    GET_INVOICES: 'l2/reports/report/invoice-report',
    EXPORT_INVOICES: 'l2/reports/report/export-invoice-report',
    GET_STATIONS: 'l2/reports/report/station-report',
    GET_TRANSACTIONS: 'l2/reports/report/transaction-report',
    GET_ITEM_PRICES: 'l2/reports/report/item-price-report',
    GET_MULTI_PAYMENTS: 'l2/reports/report/multiple-invoice-payment-report',
    EXPORT_MULTI_PAYMENTS: 'l2/reports/report/export-multiple-invoice-payment-report',
  },

  PERMISSION: {
    GET_ADMIN: 'l2/roles/get-perm2',
    GET_EMPLOYEE: 'l2/roles/get-perm3',
  },

  TAX: {
    DELETE: 'l2/tax/remove-tax',
    GET_ALL: 'l2/tax/get-tax',
    CREATE: 'l2/tax/addtax',
    ENABLE: 'l2/tax/enable-tax',
    DISABLE: 'l2/tax/disable-tax',
    GET_ALL_ACTIVE: 'l2/tax/get-active-tax',
    UPDATE: 'l2/tax/update-tax',
  },

  PRODUCT_PACKAGE: {
    CREATE: 'l2/discounts/add_product_package',
    GET_ALL: 'l2/discounts/get_product_package',
  },

  STATION: {
    CREATE: 'l2/station/new-station/create-station',
    UPDATE: 'l2/station/new-station/update-station',
    GET_BY_ID: 'l2/station/new-station/get-single-station',
    CLOSE: 'l2/station/new-station/close-station',
    GET_ALL: 'l2/station/new-station/get-station',
    GET_ALL_OPEN: 'l2/station/new-station/open-stations',
    withdraw: 'l2/withdraw/withdraw',
  },

  CUSTOMER: {
    CREATE: 'l2/customers/add-customer',
    GET_ALL: 'l2/customers/get-customer',
    IMPORT: 'l2/customers/import-customer',
    DELETE: 'l2/customers/delete-customer',
    UPDATE: 'l2/customers/update-customer',
    export: 'l2/customers/export-customer',
    DELETE_ALL: 'l2/customers/delete-customer-many',
  },

  PAYMENT_METHOD: {
    CREATE: 'l2/payment/payment-type',
    UPDATE: 'l2/payment/payment-type-update',
    GET_ALL: 'l2/payment/payment-type',
    DELETE: 'l2/payment/payment-type',
  },

  WAREHOUSE: {
    CREATE: 'l2/warehouse',
    UPDATE: 'l2/warehouse',
    DELETE: 'l2/warehouse',
    GET_ALL: 'l2/warehouse',
    GET_ONE: 'l2/warehouse/getOne',
  },

  PRODUCT: {
    GET_ALL: 'l2/products/item/get-product',
    UPDATE: 'l2/products/item/update-product',
    IMPORT: 'l2/products/item/import-product',
    GET_ALL_WAREHOUSE: 'l2/products/item/get-product-warehouse',
    DELETE: 'l2/products/item/remove-product',
    GET_BY_ID: 'l2/products/item/get-single-product',
    DELETE_IMAGE: 'l2/products/item/remove-product-pic',
    ADD_TO_WAREHOUSE: 'l2/warehouse/add-to-warehouse',
    ADD_TO_CATEGORY: 'l2/category/product-category',
    DELETE_FROM_CATEGORY: 'l2/category/product-category',
    CREATE: 'l2/products/item/add-product',
    REMOVE_FROM_WAREHOUSE: 'l2/warehouse/remove-from-warehouse',
    UPDATE_QUANTITY_IN_WAREHOUSE: 'l2/warhouse/new-ware/update-quantity-whouse',
    SEARCH_ALL: 'l2/products/item/searchProducts',
    SEARCH_IN_WAREHOUSE: 'l2/products/item/searchProductsInWarehouse',
    EXPORT: 'l2/products/item/export-product',
    TRANSFER_PRODUCT: 'l2/warehouse/transfer-warehouse',
  },

  CATEGORY: {
    CROD: 'l2/category',
    GET_ALL: 'l2/category',
    CREATE: 'l2/category',
    UPDATE: 'l2/category',
    DELETE: 'l2/category',
    DELETE_LOGO: 'l2/category/logo',
  },

  UNIT: {
    GET_ALL: 'l2/unit/get-unit',
    CREATE: 'l2/unit/add-unit',
    UPDATE: 'l2/unit/update-unit',
  },

  ROLE: {
    GET_ALL: 'l2/roles/get-roles',
    CREATE_ADMIN: 'l2/roles/create-perm2',
    UPDATE_ADMIN: 'l2/roles/edite-perm2',
    CREATE_EMPLOYEE: 'l2/roles/create-perm3',
    UPDATE_EMPLOYEE: 'l2/roles/edite-perm3',
  },

  BRANCH: {
    CREATE: 'l2/branch/create',
    GET_ALL: 'l2/branch/get',
    GET_BY_ID: 'l2/branch//get-single-branch',
    UPDATE: 'l2/branch/update',
    DISABLE: 'l2/branch/disable',
    ENABLE: 'l2/branch/enable',
  },
  GET_PRODUCT_PACKAGE: {
    CREATE: 'l2/discounts/add_product_package',
    GET_ALL: 'l2/discounts/get_product_package',
    UPDATE: 'l2/discounts/update_product_package',
  },
  STORE: {
    GET_SHOP: 'l2/shop/',
  },
  BACKUP: {
    CREATE: 'l2/backup/create',
    RESTORE: 'l2/backup/restore',
  },
  VOUCHER: {
    GET_ALL: 'l2/reports/report/voucher-report',
    EXPORT_VOUCHER_REPORT: 'l2/reports/report/export-voucher-report',
  },
  DISCOUNT: {
    CREATE: 'l2/tax/addtax',
    UPDATE: 'l2/tax/update-tax',
    DELETE: 'l2/tax/remove-tax',
    GET_ALL: 'l2/tax/get-tax',
  },
};

export default ADMIN_ENDPOINT;
