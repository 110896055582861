import 'core-js';
import 'core-js/features/set/map';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './stores/store';
import './scss/style.scss';
import LangProvider from './context/lang';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <LangProvider>
        <App />
      </LangProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.info);
