import { createSlice } from '@reduxjs/toolkit';

const initialState = { productPackage: {} };

const slice = createSlice({
  name: 'productPackageReducerEmployee',
  initialState,
  reducers: {
    GET_PRODUCT_PACKAGE: (state, action) => {
      state.productPackage = action.payload;
    },
  },
});

export const { GET_PRODUCT_PACKAGE } = slice.actions;

export default slice.reducer;
