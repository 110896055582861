import { useSelector } from 'react-redux';
import { CSpinner, CCard, CCardBody } from '@coreui/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

const LoadingPopUp = () => {
  const loading = useSelector((state) => state.theme.loading);

  if (loading)
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          bottom: 0,
          zIndex: 10000,
          background: 'rgba(0,0,0,0.4)',
        }}
      >
        <CCard
          style={{
            width: 'fit-content',
            top: '50%',
            insetInlineStart: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CCardBody className="text-center">
            <CSpinner color="info" className="mb-3" />
            <h6>
              <FormattedMessage id="ui.loading.title" />
            </h6>
          </CCardBody>
        </CCard>
      </div>
    );

  return <div />;
};

export default memo(LoadingPopUp);
