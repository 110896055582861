import { createSlice } from '@reduxjs/toolkit';

const initialState = { paymentMethods:null };

const slice = createSlice({
  name: 'SuperPaymentMethodReducer',
  initialState,
  reducers: {
    GET_PAYMENT_METHOD_SUPER: (state, action) => {
      state.paymentMethods = action.payload;
    },
  },
});

export const { GET_PAYMENT_METHOD_SUPER } = slice.actions;

export default slice.reducer;
