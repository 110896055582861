import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stations: {},
  station: {},
};

const slice = createSlice({
  name: 'AdminStationReducer',
  initialState,
  reducers: {
    GET_STATIONS_ADMIN: (state, action) => {
      state.stations = action.payload;
    },
    GET_STATION_ADMIN: (state, action) => {
      state.station = action.payload;
    },
  },
});

export const { GET_STATIONS_ADMIN, GET_STATION_ADMIN } = slice.actions;

export default slice.reducer;
