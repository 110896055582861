import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  barcode: '',
  currentPageIndex: 1,
  error: false,
  currentSaas: undefined,
};

const slice = createSlice({
  name: 'GeneralReducer',
  initialState,
  reducers: {
    READ_BARCODE: (state, action) => {
      state.barcode = action.payload;
    },
    CHANGE_CURRENT_PAGE: (state, action) => {
      state.currentPageIndex = action.payload;
    },
    CHANGE_ERROR: (state, action) => {
      state.error = action.payload;
    },
    setCurrentSaas: (state, action) => {
      state.currentSaas = action.payload;
    },
    clearCurrentSaas: (state, action) => {
      state.currentSaas = undefined;
    },
  },
});

export const selectCurrentSaas = (state) => state.general.currentSaas;

export const { READ_BARCODE, CHANGE_CURRENT_PAGE, CHANGE_ERROR, setCurrentSaas, clearCurrentSaas } = slice.actions;

export default slice.reducer;
