import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adminPermissions: {},
  employeePermissions: {},
};

const slice = createSlice({
  name: 'AdminPermissionReducer',
  initialState,
  reducers: {
    GET_ADMIN_PERMISSIONS_ADMIN: (state, action) => {
      state.adminPermissions = action.payload;
    },
    GET_EMPLOYEE_PERMISSIONS_ADMIN: (state, action) => {
      state.employeePermissions = action.payload;
    },
  },
});

export const { GET_ADMIN_PERMISSIONS_ADMIN, GET_EMPLOYEE_PERMISSIONS_ADMIN } = slice.actions;

export default slice.reducer;
