import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invoice: {},
  pendingInvoices: {},
  closeInvoices: {},
  customer: {},
  ExportInvoiceDataModalVisibel: false,
};

const slice = createSlice({
  name: 'EmployeeInvoiceReducer',
  initialState,
  reducers: {
    GET_INVOICE_INFO_EMPLOYEE: (state, action) => {
      state.invoice = action.payload;
    },

    GET_PENDING_INVOICES_EMPLOYEE: (state, action) => {
      state.pendingInvoices = action.payload;
    },
    GET_CLOSE_INVOICES_EMPLOYEE: (state, action) => {
      state.closeInvoices = action.payload;
    },
    SET_EXPORT_INVOICE_MODAL_VISIBEL: (state, action) => {
      state.ExportInvoiceDataModalVisibel = action.payload;
    },
    GET_CUSTOMER: (state, action) => {
      state.customer = action.payload;
    },
  },
});

export const {
  GET_INVOICE_INFO_EMPLOYEE,
  GET_PENDING_INVOICES_EMPLOYEE,
  SET_EXPORT_INVOICE_MODAL_VISIBEL,
  GET_CLOSE_INVOICES_EMPLOYEE,
  GET_CUSTOMER,
} = slice.actions;

export default slice.reducer;
