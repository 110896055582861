/* eslint-disable import/prefer-default-export */
const sassName = window.location.href.split('/')?.[3];

const SUPER = `/${sassName}/super`;
const ADMIN = `/${sassName}/admin`;
const SASS = `/saas`;
const EMPLOYEE = `/${sassName}/employee`;
const EMPLOYEECOPY = `/${sassName}/employeeCopy`;
const LandingPage = `/Landing`;
const PRIVATE = `/${sassName}/dashboard`;
const ROUTE_PREFIX = { SUPER, ADMIN, EMPLOYEE, LandingPage, PRIVATE, SASS, EMPLOYEECOPY };

export default ROUTE_PREFIX;
