import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: {},
};

const slice = createSlice({
  name: 'AdminUserReducer',
  initialState,
  reducers: {
    GET_USERS_ADMIN: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const { GET_USERS_ADMIN } = slice.actions;

export default slice.reducer;
