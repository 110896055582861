const SUPER_ENDPOINT = {
  AUTH: {
    LOGIN: 'l1/auth/super-admin/login',
  },

  STORE: {
    CREATE: 'l1/shop-super-admin/admin-create-new-shop',
    GET_ALL: 'l1/shop-super-admin/get-shop',
    UPDATE: 'l1/shop-super-admin/update-shop',
    GET_BY_ID: 'l1/shop-super-admin/specific-shop',
    GET_SHOP: 'l1/shop-super-admin/get-shop',
  },

  CATEGORY: {
    CROD: 'l1/category',
    GET_ALL: 'l1/category',
    CREATE: 'l1/category',
    UPDATE: 'l1/category',
    DELETE: 'l1/category',
    DELETE_LOGO: 'l1/category/logo',
  },

  TAX: {
    CREATE: 'l1/tax/new-tax/create-tax',
    GET_ALL: 'l1/tax/get-tax',
    GET_ALL_ACTIVE: 'l1/tax/get-active-tax',
    REMOVE: 'l1/tax/delete/remove-tax',
    UPDATE: 'l1/tax/new-tax/update-tax',
    DISABLE: 'l1/tax/disable-tax',
    ENABLE: 'l1/tax/enable-tax',
  },

  DASHBOARD: {
    STATISTICS: 'l1/dashboard',
    ORDER_SUMMERY: 'l1/dashboard/orderSummery',
    All_INVOICES: 'l1/dashboard/allInvoices',
    EMPLOYEES: 'l1/dashboard/employees',
    TOP_CUSTOMERS: 'l1/dashboard/topCustomers',
    MOST_SOLD_PRODUCTS: 'l1/dashboard/mostSoldProducts',
  },

  PAYMENT_METHOD: {
    CREATE: 'l1/payments/payment-type',
    GET_ALL: 'l1/payments/payment-type-lists',
    UPDATE: 'l1/payments/update-payment',
    DELETE: 'l1/payments/payment-type',
  },

  ADMIN: {
    CREATE: 'l1/shop-super-admin/new-admin',
    UPDATE: 'l1/shop-super-admin/update-shop-admin',
    GET_ALL: 'l1/shop-super-admin/get-shopadmin',
    DISABLE: 'l1/shop-super-admin/disableShopAdmin',
    ENABLE: 'l1/shop-super-admin/enableShopAdmin',
  },

  USER: {
    CREATE: 'l1/admin/new-super-admin',
    UPDATE: 'l1/admin/update-super-admin',
    GET_ALL: 'l1/admin/super-admin-list',
  },

  WAREHOUSE: {
    CREATE: 'l1/warehouse',
    UPDATE: 'l1/warehouse',
    DELETE: 'l1/warehouse',
    GET_ALL: 'l1/warehouse',
    GET_ONE: 'l1/warehouse/getOne',
  },

  PRODUCT: {
    CREATE: 'l1/items/products/add-product',
    UPDATE: 'l1/items/products/update-product',
    DELETE: 'l1/items/products/remove-product',
    GET_ALL: 'l1/items/products/get-product',
    GET_BY_ID: 'l1/items/products/get-single-product',
    DELETE_IMAGE: 'l1/items/products/remove-product-pic',
    IMPORT_ALL: 'l1/items/products/import-product',
    IMPORT_AL_SHOP_CUSTOMER: 'l1/customer/import-customer',
    REMOVE_FROM_WAREHOUSE: 'l1/warehouse/remove-from-warehouse',
    UPDATE_QUANTITY_WAREHOUSE: 'l1/warhouse/new-ware/update-quantity-whouse',
    ADD_TO_CATEGORY: 'l1/category/product-category',
    DELETE_FROM_CATEGORY: 'l1/category/product-category',
    SEARCH_ALL: 'l1/items/products/searchProducts',
    SEARCH_ALL_WAREHOUSE: 'l1/items/products/searchProductsInWarehouse',
    ADD_TO_WAREHOUSE: 'l1/warehouse/add-to-warehouse',
    GET_ALL_WAREHOUSE: 'l1/warhouse/new-ware/get-whouse',
    GET_ALL_WAREHOUSE2: 'l1/warhouse/new-ware/get-warehouse',
    EXPORT: 'l1/items/products/export-product',
    TRANSFER_PRODUCT: 'l1/warehouse/transfer-warehouse',
  },
  GETSHOPCUSTOMER: {
    GETALL: 'l1/customer/get-customer',
    CREATE: 'l1/customer/add-customer',
    UPDATE: 'l1/customer/update-customer',
    DELETE: 'l1/customer/delete-customer',
    IMPORT: 'l1/customer/import-customer',
    export: 'l1/customer/export-customer',
    DELETE_ALL: 'l1/customer/delete-customer-many',
  },

  UNIT: {
    CREATE: 'l1/units/new-inits/add-unit',
    GET_ALL: 'l1/units/item-unit/get-unit',
    UPDATE: 'l1/units/new-inits/update-unit',
  },

  ROLE: {
    CREATE_SUPER: 'l1/roles/create-perm1',
    UPDATE_SUPER: 'l1/roles/edite-perm1',
    CREATE_ADMIN: 'l1/roles/create-perm2',
    UPDATE_ADMIN: 'l1/roles/edite-perm2',
    CREATE_EMPLOYEE: 'l1/roles/create-perm3',
    UPDATE_EMPLOYEE: 'l1/roles/edite-perm3',
    GET_ALL: 'l1/roles/get-roles',
  },

  PERMISSION: {
    GET_SUPER: 'l1/roles/get-perm1',
    GET_ADMIN: 'l1/roles/get-perm2',
    GET_EMPLOYEE: 'l1/roles/get-perm3',
  },

  REPORT: {
    GET_INVOICES: 'l1/reports/report/invoice-report',
    EXPORT_INVOICES: 'l1/reports/report/export-invoice-report',
    GET_STATIONS: 'l1/reports/report/station-report',
    GET_TRANSACTIONS: 'l1/reports/report/transaction-report',
    GET_ITEM_PRICES: 'l1/reports/report/item-price-report',
    GET_MULTI_PAYMENTS: 'l1/reports/report/multiple-invoice-payment-report',
    DELETE_ALL_OPEN_INVOICE_OF_SAAS: 'l1/invoice/deleteOpenInvoicesOfSaas',
    EXPORT_MULTI_PAYMENTS: 'l1/reports/report/export-multiple-invoice-payment-report',
  },

  EMPLOYEE: {
    CREATE: 'l1/employee/add-emp',
    UPDATE: 'l1/employee/update-emp',
    GET_ALL: 'l1/employee/get',
    GET_ALL_BY_STORE: 'l1/employee/getbyShop',
    DISABLE: 'l1/employee/disableEmployee',
    ENABLE: 'l1/employee/enableEmployee',
  },

  STATION: {
    CLOSE: 'l1/station/new-station/close-station',
    CREATE: 'l1/station/new-station/create-station',
    GET_ALL_OPEN: 'l1/station/new-station/open-stations',
    GET_ALL: 'l1/station/new-station/get-station',
    GET_BY_ID: 'l1/station/new-station/get-single-station',
    UPDATE: 'l1/station/new-station/update-stations',
    withdraw: 'l1/withdraw/make-withdraw',
  },

  BRANCH: {
    CREATE: 'l1/branch/create',
    GET_ALL_BY_STORE: 'l1/branch/get',
    GET_BY_ID: 'l1/branch//get-single-branch',
    UPDATE: 'l1/branch/update',
    DISABLE: 'l1/branch/disable',
    ENABLE: 'l1/branch/enable',
  },
  SCHEMA: {
    SAVE: 'l1/reports/report/add-design',
    GETONE: 'l1/reports/report/get-design',
    GETAll: 'l1/reports/report/get-all-design',
    DELETE: 'l1/reports/report/delete-design',
  },
  INVOICE_POINT: {
    GET_ALL: 'l1/discounts/get_invoice_point',
    CREATE: 'l1/discounts/add_invoice_point',
    UPDATE: 'l1/discounts/update_invoice_point',
  },
  DISCOUNT_POINT: {
    GET_ALL: 'l1/discounts/get_discount_invoice',
    CREATE: 'l1/discounts/add_invoice_point',
    CREATE_DISCOUNT_INVOICE: 'l1/discounts/add_discount_invoice',
    UPDATE: 'l1/discounts//update_discount_invoice',
  },
  GET_PRODUCT_PACKAGE: {
    CREATE: 'l1/discounts/add_product_package',
    UPDATE: 'l1/discounts/update_product_package',
    GET_ALL: 'l1/discounts/get_product_package',
  },
  PUBLIC: {
    CHECK_SASS_NAME: 'v1/public/get-single-saas',
  },
  BACKUP: {
    CREATE: 'l1/backup/create',
    RESTORE: 'l1/backup/restore',
  },
  VOUCHER: {
    GET_ALL: 'l1/reports/report/voucher-report',
    EXPORT_VOUCHER_REPORT: 'l1/reports/report/export-voucher-report',
  },
  DISCOUNT: {
    CREATE: 'l1/tax/new-tax/create-tax',
    UPDATE: 'l1/tax/new-tax/update-tax',
    DELETE: 'l1/tax/delete/remove-tax',
    GET_ALL: 'l1/tax/get-tax',
  },
  TICKET: {
    GET_TICKETS: 'l1/ticket/get-ticket',
    CREATE_TICKETS: 'l1/ticket/create-ticket',
    SEND_TICKETS_MESSAGE: 'l1/ticket/send-msg',
    UPDATE_TICKETS: 'l1/ticket/update-ticket',
  },
};

export default SUPER_ENDPOINT;
