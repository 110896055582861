import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employees: {},
};

const slice = createSlice({
  name: 'SuperEmployeeReducer',
  initialState,
  reducers: {
    GET_EMPLOYEES_SUPER: (state, action) => {
      state.employees = action.payload;
    },
  },
});

export const { GET_EMPLOYEES_SUPER } = slice.actions;

export default slice.reducer;
