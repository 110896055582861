import { createSlice } from '@reduxjs/toolkit';

const initialState = { customers: {} };

const slice = createSlice({
  name: 'AdminCustomerReducer',
  initialState,
  reducers: {
    GET_CUSTOMERS_ADMIN: (state, action) => {
      state.customers = action.payload;
    },
  },
});

export const { GET_CUSTOMERS_ADMIN } = slice.actions;

export default slice.reducer;
