import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  superRoles: {},
  adminRoles: {},
  employeeRoles: {},
};

const slice = createSlice({
  name: 'SuperRoleReducer',
  initialState,
  reducers: {
    GET_SUPER_ROLES_SUPER: (state, action) => {
      state.superRoles = action.payload;
    },
    GET_ADMIN_ROLES_SUPER: (state, action) => {
      state.adminRoles = action.payload;
    },
    GET_EMPLOYEE_ROLES_SUPER: (state, action) => {
      state.employeeRoles = action.payload;
    },
  },
});

export const { GET_SUPER_ROLES_SUPER, GET_ADMIN_ROLES_SUPER, GET_EMPLOYEE_ROLES_SUPER } = slice.actions;

export default slice.reducer;
