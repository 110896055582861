import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  units: {},
};

const slice = createSlice({
  name: 'SuperUnitReducer',
  initialState,
  reducers: {
    GET_UNITS_SUPER: (state, action) => {
      state.units = action.payload;
    },
  },
});

export const { GET_UNITS_SUPER } = slice.actions;

export default slice.reducer;
