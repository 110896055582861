import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  vouchers: {},
};

const slice = createSlice({
  name: 'VoucherReducer',
  initialState,
  reducers: {
    GET_VOUCHERS: (state, action) => {
      state.vouchers = action.payload;
    },
  },
});

export const { GET_VOUCHERS } = slice.actions;

export default slice.reducer;
