import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shopCustomers: {},
  shopCustomer: {},
};

const slice = createSlice({
  name: 'SuperShopCustomerReducer',
  initialState,
  reducers: {
    GET_SHOPCUSTOMERS_SUPER: (state, action) => {
      state.shopCustomers = action.payload;
    },
  },
});

export const { GET_SHOPCUSTOMERS_SUPER } = slice.actions;

export default slice.reducer;
