const EMPLOYEE_ENDPOINT = {
  AUTH: {
    LOGIN: 'v1/l3/login',
    LOGOUT: 'v1/l3/logout',
  },

  INVOICE: {
    CREATE: 'v1/l3/invoice/create-invoice',
    CREATE_ITEM: 'v1/l3/invoice/add-item-invoice',
    ADD_PRODUCT_PACK: 'v1/l3/invoice/add-package-invoice',
    FINISH: 'v1/l3/invoice/close-invoice',
    DELETE_ITEM: 'v1/l3/invoice/remove-item-invoice',
    UPDATE_STATUS: 'v1/l3/invoice/toggle-invoice',
    GET_ALL_PENDING: 'v1/l3/invoice/get-pending',
    GET_ALL_CLOSE: 'v1/l3/invoice/get-close-invoice',
    GET_BY_ID: 'v1/l3/invoice/get-single-pending',
    CALCULATE_REFUND_PRICE: 'v1/l3/invoice/return-item-cost',
    RETURNITEM: 'v1/l3/invoice/return-item',
    GET_CUSTOMER: 'v1/l3/customer/get_customer',
    CREATE_CUSTOMER: 'v1/l3/customer/createCustomer',
    UPDATE_ITEM: 'v1/l3/invoice/update-item-invoice',
    overRidePrice: 'v1/l3/invoice/override-invoice',
    APPLY_DISCOUNT: 'v1/l3/invoice/use-point',
    UPDATE_INVOICE: 'v1/l3/invoice/update-invoice',
  },

  CATEGORY: {
    GET_ALL: 'v1/l3/invoice/get-categorys',
  },

  PRODUCT: {
    GET_ALL_WAREHOUSE: 'v1/l3/products/get-product-warehouse',
    SEARCH_BARCODES: 'v1/l3/products/product',
    SEARCH_ALL: 'v1/l3/products/searchProducts',
    GET_PRODUCTS: 'v1/l3/products/get-product',
    SEARCH_ALL_WAREHOUSE: 'v1/l3/products/search-warehouse',
  },

  DASHBOARD: {
    STATISTICS: 'v1/l3/dashboard',
    ORDER_SUMMERY: 'v1/l3/dashboard/orderSummery',
    All_INVOICES: 'v1/l3/dashboard/allInvoices',
    EMPLOYEES: 'v1/l3/dashboard/employees',
    TOP_CUSTOMERS: 'v1/l3/dashboard/topCustomers',
    MOST_SOLD_PRODUCTS: 'v1/l3/dashboard/mostSoldProducts',
  },

  STATION: {
    UPDATE_STATUS: 'v1/l3/station/update-station-status',
    GET_STATION: 'v1/l3/station/get-station-data',
  },

  PAYMENT_METHOD: {
    GET_PAYMENT_METHODS: 'v1/l3/payment/get-payment',
  },
  GET_PRODUCT_PACKAGE: {
    GET_ALL: 'v1/l3/discounts/get_product_package',
  },
  VOUCHER: {
    ADD: 'v1/l3/invoice/create-voucher',
  },
};

export default EMPLOYEE_ENDPOINT;
