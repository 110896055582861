import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employees: {},
};

const slice = createSlice({
  name: 'AdminEmployeeReducer',
  initialState,
  reducers: {
    GET_EMPLOYEES_ADMIN: (state, action) => {
      state.employees = action.payload;
    },
  },
});

export const { GET_EMPLOYEES_ADMIN } = slice.actions;

export default slice.reducer;
