/* eslint-disable import/prefer-default-export */
import { isEmpty, isUndefined } from 'underscore';
import { hasAccess } from './authorization.helpers';

/**
 * Filter sidebar items.
 *
 * @param {string} panel `admin`, `super`, or `employee`
 * @param {array} sidebarItems
 * @returns
 */
export const filterSidebarItems = (panel, sidebarItems) => {
  const filter = (sidebarItem) => {
    if (!isUndefined(sidebarItem.visible) && sidebarItem.visible === false) return false;
    if (!isUndefined(sidebarItem.permission) && !isUndefined(sidebarItem.subpermission)) {
     
      return hasAccess(panel, sidebarItem.permission, sidebarItem.subpermission);
    }

    return true;
  };

  // Filter items
  let filteredItems = sidebarItems.filter((item) => filter(item));

  // Filter subitems if has any then filter items if all subitems are filtered
  filteredItems = filteredItems.filter(({ subitems }, itemIndex) => {
    if (!isEmpty(subitems)) {
      const filteredSubitems = subitems.filter((subitem) => filter(subitem));
      if (!isEmpty(filteredSubitems)) {
        filteredItems[itemIndex].subitems = filteredSubitems;
        return true;
      }

      return false;
    }

    return true;
  });

  return filteredItems;
};
