/* eslint-disable import/prefer-default-export */
import { isMatch, isUndefined } from 'underscore';
import { LocalStorage } from '../../Services';

/**
 * Check permissions.
 *
 * @param {string} userProfile
 * @param {string|undefined} checkAgainstPermissions `undefined` means no need to check authorization
 * @param {object|undefined} checkAgainstSubPermissions `undefined` means no need to check authorization
 * @returns
 */
const checkPermission = (userProfile, checkAgainstPermissions, checkAgainstSubPermissions) => {
  if (isUndefined(checkAgainstPermissions) || isUndefined(checkAgainstSubPermissions)) return true;

  const {
    role: { permissions: userPermissions },
  } = JSON.parse(userProfile);

  return (
    userPermissions.filter(
      (permission) =>
        isMatch(permission, { Code: checkAgainstPermissions }) &&
        isMatch(permission.sub_perm, checkAgainstSubPermissions),
    ).length !== 0
  );
};

/**
 * Check the current user access.
 *
 * @param {string} panel `admin`, `super`, or `employee`
 * @param {string|undefined} checkAgainstPermissions `undefined` means no need to check authorization
 * @param {object|undefined} checkAgainstSubPermissions `undefined` means no need to check authorization
 * @returns {boolean} `true` if the current user has the permission
 */
export const hasAccess = (panel, checkAgainstPermissions, checkAgainstSubPermissions) => {
  let userProfile;
  if (panel === 'super') {
    userProfile = LocalStorage.get('super_profile');
  } else if (panel === 'admin') {
    userProfile = LocalStorage.get('admin_profile');
  } else if (panel === 'employee') {
    userProfile = LocalStorage.get('employee_profile');
  } else if (panel === 'sass') {
    userProfile = LocalStorage.get('saas_profile');
  }
  return checkPermission(userProfile, checkAgainstPermissions, checkAgainstSubPermissions);
};
