/* eslint-disable global-require */
/* eslint-disable prefer-const */
/* eslint-disable import/no-mutable-exports */
import React, {useState, useEffect} from 'react'


export let LangContext = React.createContext();

let LangProvider = ({children}) =>{
    let [lang, setLang] = useState('en');

    let langs = {
      en: () => require('./lang/en.json'),
      ar: () => require('./lang/ar.json'),
    };
  
    let getLang = (page) => langs[lang]()[page];
  
    useEffect(() => {
      let local = localStorage.getItem('lang');
      if (local) {
        setLang(local);
      }
    }, []);
  
    // useEffect(() => {
    //   localStorage.setItem('lang', lang);
    //   if (lang === 'ar') {
    //     document.body.classList.remove('ltr');
    //     document.body.classList.add('rtl');
    //     document.body.dir = 'rtl';
    //   } else {
    //     document.body.classList.remove('rtl');
    //     document.body.classList.add('ltr');
    //     document.body.dir = 'ltr';
    //   }

    // }, [lang]);
    return (
        <LangContext.Provider
            value={{
            getLang,
            setLang,
            lang,
            }}
      >
        {children}
      </LangContext.Provider>
    )
}
export default LangProvider;
