import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sasses: {}
};

const slice = createSlice({
  name: 'SassReducer',
  initialState,
  reducers: {
    SAVE_ALL_SASS: (state, action) => {
      state.sasses = action.payload;
    },
  },
});

export const { SAVE_ALL_SASS } = slice.actions;

export default slice.reducer;
