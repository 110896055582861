import { createSlice } from '@reduxjs/toolkit';

const initialState = { admins: {} };

const slice = createSlice({
  name: 'SuperAdminReducer',
  initialState,
  reducers: {
    GET_ADMINS_SUPER: (state, action) => {
      state.admins = action.payload;
    },
  },
});

export const { GET_ADMINS_SUPER } = slice.actions;

export default slice.reducer;
