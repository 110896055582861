import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invoices: {},
  stations: {},
  transactions: {},
  itemPrices: {},
  multiPays: {},
};

const slice = createSlice({
  name: 'SuperReportReducer',
  initialState,
  reducers: {
    GET_INVOICE_REPORTS_SUPER: (state, action) => {
      state.invoices = action.payload;
    },
    GET_STATION_REPORTS_SUPER: (state, action) => {
      state.stations = action.payload;
    },
    GET_TRANSACTION_REPORTS_SUPER: (state, action) => {
      state.transactions = action.payload;
    },
    GET_ITEM_PRICE_REPORTS_SUPER: (state, action) => {
      state.itemPrices = action.payload;
    },
    GET_MULTI_PAY_REPORTS_SUPER: (state, action) => {
      state.multiPays = action.payload;
    },
  },
});

export const {
  GET_INVOICE_REPORTS_SUPER,
  GET_MULTI_PAY_REPORTS_SUPER,
  GET_ITEM_PRICE_REPORTS_SUPER,
  GET_STATION_REPORTS_SUPER,
  GET_TRANSACTION_REPORTS_SUPER,
} = slice.actions;

export default slice.reducer;
