import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  discountInvoices: {},
  invoicePoints: {},
  discountInvoice: {},
  invoicePoint: {},
};

const slice = createSlice({
  name: 'AdminTransactionReducer',
  initialState,
  reducers: {
    GET_DISCOUNT_INVOICES_ADMIN: (state, action) => {
      state.discountInvoices = action.payload;
    },
    GET_INVOICE_POINTS_ADMIN: (state, action) => {
      state.invoicePoints = action.payload;
    },
    GET_DISCOUNT_INVOICE_ADMIN: (state, action) => {
      state.discountInvoice = action.payload;
    },
    GET_INVOICE_POINT_ADMIN: (state, action) => {
      state.invoicePoint = action.payload;
    },
  },
});

export const {
  GET_DISCOUNT_INVOICES_ADMIN,
  GET_INVOICE_POINTS_ADMIN,
  GET_DISCOUNT_INVOICE_ADMIN,
  GET_INVOICE_POINT_ADMIN,
} = slice.actions;

export default slice.reducer;
