import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  discounts: {},
};

const slice = createSlice({
  name: 'AdminDiscountReducer',
  initialState,
  reducers: {
    GET_DISCOUNTS_ADMIN: (state, action) => {
      state.discounts = action.payload;
    },
  },
});

export const { GET_DISCOUNTS_ADMIN } = slice.actions;

export default slice.reducer;
