import { createSlice } from '@reduxjs/toolkit';

const initialState = { saasManagers: [] };

const slice = createSlice({
  name: 'saasManagerReducer',
  initialState,
  reducers: {
    SAVE_ALL_SAAS_MANAGER: (state, action) => {
      state.saasManagers = action.payload;
    },
  },
});

export const { SAVE_ALL_SAAS_MANAGER } = slice.actions;

export default slice.reducer;
