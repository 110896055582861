/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { isString } from 'underscore';
import { LocalStorage } from '../../Services';

/**
 * Check token expiration.
 *
 * @param {string} panel `admin`, `super`, or `employee`
 * @returns {boolean} `true` means the token is expired
 */
const isExpired = (panel) => {
  let tokenExpires;
  if (panel === 'super') {
    tokenExpires = LocalStorage.get('super_token_expires');
  } else if (panel === 'admin') {
    tokenExpires = LocalStorage.get('admin_token_expires');
  } else if (panel === 'employee') {
    tokenExpires = LocalStorage.get('employee_token_expires');
  } else if (panel === 'sass') {
    tokenExpires = LocalStorage.get('sass_token_expires');
  }

  if (!isString(tokenExpires)) return true;

  return moment(tokenExpires).isSameOrBefore();
};

/**
 * Check token validation.
 *
 * @param {string} panel `admin`, `super`, or `employee`
 * @returns `true` means the token is valid
 */
const isTokenValid = (panel) => {
  let token;
  if (panel === 'super') {
    token = LocalStorage.get('super_token');
  } else if (panel === 'admin') {
    token = LocalStorage.get('admin_token');
  } else if (panel === 'employee') {
    token = LocalStorage.get('employee_token');
  } else if (panel === 'sass') {
    token = LocalStorage.get('sass_token');
  }
  return isString(token);
};

export const isAuthenticated = (panel) => {
  return isTokenValid(panel) && !isExpired(panel);
};
