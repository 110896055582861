import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { useEffect, useState } from 'react';
import { LocalStorage } from '../../../Services';
import { hasAccess } from '../../../utils/helpers';

const AuthorizedRoute = ({ children, permission, subpermission, panel, ...rest }) => {
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    let userProfile;
    if (panel === 'super') {
      userProfile = LocalStorage.get('super_profile');
    } else if (panel === 'admin') {
      userProfile = LocalStorage.get('admin_profile');
    } else if (panel === 'employee') {
      userProfile = LocalStorage.get('employee_profile');
    } else if (panel === 'sass') {
      userProfile = LocalStorage.get('saas_profile');
    }
    if (!isEmpty(userProfile)) {
      userProfile = JSON.parse(userProfile);
      setUserPermissions(userProfile.role.permissions);
    }
  }, [panel]);

  const isAuthorized = () => {
    return !isEmpty(userPermissions) && hasAccess(panel, permission, subpermission);
  };

  if (!isEmpty(userPermissions))
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthorized() === true ? children : <Redirect to={{ pathname: '/403', state: { from: location } }} />
        }
      />
    );

  return <div />;
};

AuthorizedRoute.propTypes = {
  permission: PropTypes.string,
  subpermission: PropTypes.shape({ can_add: PropTypes.bool, can_remove: PropTypes.bool, can_update: PropTypes.bool }),
  panel: PropTypes.oneOf(['super', 'admin', 'employee', 'sass']).isRequired,
};

AuthorizedRoute.defaultProps = {
  permission: undefined,
  subpermission: undefined,
};

export default AuthorizedRoute;
