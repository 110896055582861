import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
  name: 'superAdminReducer',
  initialState,
  reducers: {
    SAVE_ALL_SUPER_ADMIN: (state, action) => {
      state.superAdmins = action.payload;
    },
  },
});

export const { SAVE_ALL_SUPER_ADMIN } = slice.actions;

export default slice.reducer;
