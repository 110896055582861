/* eslint-disable eqeqeq */
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isAuthenticated } from '../../../utils/helpers';

const ProtectedRoute = ({ component: ProtectedComponent, redirectTo, panel, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        isAuthenticated(panel) === true ? (
          <ProtectedComponent {...props} />
        ) : (
          <Redirect to={{ pathname: `${rest.path}/login`, state: { from: location } }} />
          // <Redirect to={{ pathname: `${rest.path}/login`, state: { from: location } }} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  panel: PropTypes.oneOf(['super', 'admin', 'employee', 'sass']).isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default ProtectedRoute;
