/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */

import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'underscore';
import { LocalStorage } from '../../Services';
import { isRtl } from './i18n.helpers';

/* eslint-disable no-useless-concat */
const ADMINDASHBOARD = '0';
const URLS = [{ adminIconsAssetsBaseUrl: '/assets/images/sidebar/adminIcons/' }];
export const returnSliderIcon = (icon, style) => {
  let iconPath = `${URLS[ADMINDASHBOARD].adminIconsAssetsBaseUrl}${icon}.png`;
  return <img className="" src={iconPath} style={{ ...style, marginInlineEnd: '5px' }} />;
  // return <img className="" src={iconPath} style={{ paddingRight: '10px' }} />;
};

// return base url ============================
export const returnBaseUrl = (webservice) => {
  const currentURL = window.location.host;
  if (currentURL.search('localhost:') !== -1) {
    // return webservice ? 'https://osuspos.com/backend/' : 'https://osuspos.com/';
    return webservice ? 'http://localhost:3000/backend/' : 'https://osuspos.com/';
    // eslint-disable-next-line no-else-return
  } else return webservice ? `https://${currentURL}/backend/` : `https://${currentURL}/`;
};
// create selectable list ==============================
export const createSelectableList = (list, label = 'name', value = '_id') => {
  if (!Array.isArray(list)) return [];
  const selectableList = [];
  list?.forEach((item) => {
    selectableList.push({
      value: item[value],
      label: item[label],
      data: item,
    });
  });
  return selectableList;
};

export const queryCreator = (obj = {}) => {
  let query = '';
  // eslint-disable-next-line no-restricted-syntax
  for (let [key, value] of Object.entries(obj)) {
    if (value) {
      if (query === '') query = `${key}=${value}`;
      else query = `${query}&${key}=${value}`;
    }
  }
  return query;
};

// return date =============================
export const returnDate = (date) => {
  const dateOnly = date?.slice(0, 10);
  return dateOnly;
};

// excel columns creator ===========================
export const excelColumnsCreator = (charA, charZ) => {
  const a = [];
  let i = charA.charCodeAt(0);
  const j = charZ.charCodeAt(0);
  for (; i <= j; ++i) {
    a.push({ value: String.fromCharCode(i), label: String.fromCharCode(i) });
  }
  return a;
};

// table column generator =================================
export const tableColumnGenerator = (list, columns) => {
  const result = {};
  list.forEach((item, i) => {
    if (columns[i]?.columns) {
      columns[i]?.columns.forEach((subColumn, index) => {
        result[subColumn?.accessor] = item[index];
      });
    } else result[columns[i]?.accessor] = item;
  });

  return result;
};

// convert date string to normal date =====================

export const convertDate = (date) => {
  const lang = LocalStorage.get('language') || 'en';
  let resultString;
  if (lang === 'en') {
    const newDate = new Date(date);
    resultString = newDate.toUTCString();
  } else {
    const newDate = new Date(date);
    const months = [
      'يناير',
      'فبراير',
      'مارس',
      'إبريل',
      'مايو',
      'يونيو',
      'يوليو',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];
    const days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    resultString =
      days[newDate.getDay()] +
      ', ' +
      newDate.getDate() +
      ' ' +
      months[newDate.getMonth()] +
      ', ' +
      newDate.getFullYear() +
      ' - ' +
      newDate.getHours() +
      ' : ' +
      newDate.getMinutes();
  }

  return resultString;
};

// multilanguage toast =====================

export const multilanguageToast = (msg, type = 'success', values) => {
  // const intl = useIntl();

  const title = <FormattedMessage key={Math.random(0, 1000)} id={msg} values={values} />;
  // eslint-disable-next-line no-unused-expressions
  switch (type) {
    case 'success':
      toast.success(title);
      break;
    case 'warn':
      toast.warning(title);
      break;

    default:
      toast.error(title);
      break;
  }
  // type !== 'success' ? toast.error(title) : toast.success(title);
};

export const Welcome = (title, layout = 'super_profile') => {
  const SUPER_ADMIN_INFO = LocalStorage.get(layout);
  const USER_NAME = JSON.parse(SUPER_ADMIN_INFO)?.userName;
  return (
    <h1 className="display-5 fw-bold">
      <FormattedMessage id={title} />
      {` ,${USER_NAME}`}
    </h1>
  );
};

export const fixPositionByLang = (pos, currentLanguage) => {
  let ltrPosition;
  let rtlPosition;
  if (isRtl(currentLanguage)) {
    rtlPosition = { x: pos.x, y: pos.y };
    ltrPosition = { x: pos.x * -1, y: pos.y };
  } else {
    rtlPosition = { x: pos.x * -1, y: pos.y };
    ltrPosition = { x: pos.x, y: pos.y };
  }
  return { rtlPosition, ltrPosition };
};

export const showNationalAddress = (item) => {
  if (!isEmpty(item)) return Object.keys(item).map((key) => <>{`${item[key] + '/'}`}</>);
};

export const fixPositionOnResize = (position, delta, dir, currentLanguage) => {
  const newPosition = { ...position };
  if (
    (isRtl(currentLanguage) && ['right', 'topRight', 'bottomRight'].includes(dir)) ||
    (!isRtl(currentLanguage) && ['left', 'topLeft', 'bottomLeft'].includes(dir))
  ) {
    newPosition.ltrPosition = {
      x: (position?.ltrPosition?.x || 0) - delta.width,
      y: position?.ltrPosition?.y || 0,
    };
    newPosition.rtlPosition = {
      x: (position?.rtlPosition?.x || 0) + delta.width,
      y: position?.rtlPosition?.y || 0,
    };
  }

  // console.log('>>>>>>>>>>> newPosition', newPosition);
  return newPosition;
};

export const getPosition = (position, currentLanguage) => {
  if (isRtl(currentLanguage)) return position?.rtlPosition || { x: 0, y: 0 };
  return position?.ltrPosition || { x: 0, y: 0 };
};

export const a2e = (s) => s.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));

export const getShopData = () => {
  const currentDashboard = localStorage.getItem('current_dashboard');
  let saas = {};
  if (currentDashboard === 'employee') saas = JSON.parse(localStorage.getItem('employee_profile'))?.saas?.[0];
  else if (currentDashboard === 'admin') saas = JSON.parse(localStorage.getItem('admin_profile'))?.saas?.[0];
  return { shop: saas, currency: saas?.currency, currentDashboard };
};

export const returnDashboard = () => {
  const currentDashboard = localStorage.getItem('current_dashboard');

  if (currentDashboard === 'admin') {
    return 'admin_profile';
    // eslint-disable-next-line no-else-return
  } else if (currentDashboard === 'employee') {
    return 'employee_profile';
  } else return null;
};

export const getCurrentBranch = () => {
  return JSON.parse(localStorage.getItem(returnDashboard()))?.branch[0];
};

export const getAdminProfile = () => {
  const currentDashboard = localStorage.getItem('current_dashboard');

  let profileKey;

  if (currentDashboard === 'Sass') profileKey = 'saas_profile';
  if (currentDashboard === 'super') profileKey = 'super_profile';
  if (currentDashboard === 'admin') profileKey = 'admin_profile';
  if (currentDashboard === 'employee') profileKey = 'employee_profile';

  const profile = localStorage.getItem(profileKey);
  if (!profile) return null;
  return JSON.parse(profile);
};

export const downloader = (data, fileName, type) => {
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
};
