import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  discountInvoices: {},
  invoicePoints: {},
};

const slice = createSlice({
  name: 'SuperTransactionReducer',
  initialState,
  reducers: {
    GET_DISCOUNT_INVOICES_SUPER: (state, action) => {
      state.discountInvoices = action.payload;
    },
    GET_INVOICE_POINTS_SUPER: (state, action) => {
      state.invoicePoints = action.payload;
    },
  },
});

export const { GET_DISCOUNT_INVOICES_SUPER, GET_INVOICE_POINTS_SUPER } = slice.actions;

export default slice.reducer;
