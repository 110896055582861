import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stations: {},
  station: {},
};

const slice = createSlice({
  name: 'SuperStationReducer',
  initialState,
  reducers: {
    GET_STATIONS_SUPER: (state, action) => {
      state.stations = action.payload;
    },
    GET_STATION_SUPER: (state, action) => {
      state.station = action.payload;
    },
  },
});

export const { GET_STATIONS_SUPER, GET_STATION_SUPER } = slice.actions;

export default slice.reducer;
